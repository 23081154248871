import { Typography } from "@mui/material";
import "./paginer.css";
import { PaginerEssential, HallEvent } from "../../../../../../Type";
import { ReactElement, RefObject, createRef } from "react";
import { ProgressBar } from "../ProgressBar/ProgressBar";

export function Paginer(props: PaginerEssential) {
  const parentDivLeft: RefObject<HTMLDivElement> = createRef();
  const childDiv: RefObject<HTMLDivElement> = createRef();
  const eventAnim = () => animFold(parentDivLeft, childDiv);

  animUnFold(parentDivLeft, childDiv);

  let page = createPage(props.events, props.page, props.numberOfEventPerPage);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
        }}
        ref={childDiv}
      >
        {page.map((element: any, index: number) => {
          return eventDisplayer(element, index);
        })}
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#f28f22",
          position: "absolute",
          left: "0px",
        }}
        ref={parentDivLeft}
      ></div>
      {props.numberOfEventPerPage >= props.events.length ? (
        <></>
      ) : (
        <ProgressBar
          eventPageManager={props.eventPageManager}
          eventFold={eventAnim}
          page={props.page}
          numberOfPage={Math.ceil(
            props.events.length / props.numberOfEventPerPage
          )}
        ></ProgressBar>
      )}
    </>
  );
}

function createPage(
  array: HallEvent[],
  page: number,
  numberOfEventPerPage: number
): HallEvent[] {
  let buffer: HallEvent[] = [];
  if (
    array.length - 1 <
    page * numberOfEventPerPage + numberOfEventPerPage - 1
  ) {
    buffer = array.slice(page * numberOfEventPerPage, array.length);
  } else {
    buffer = array.slice(
      page * numberOfEventPerPage,
      page * numberOfEventPerPage + numberOfEventPerPage
    );
  }
  return buffer;
}

function eventDisplayer(element: HallEvent, index: number): ReactElement {
  let masterDisplayName = element.DisplayNameofVisitors;

  return (
    <div className="eventLine" key={index}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          flexGrow: "1",
        }}
      >
        <Typography variant="h6">{masterDisplayName}</Typography>
        <Typography variant="body1">
          Hôte : {formatName(element.AltyorMateString)}
        </Typography>
        <Typography variant="body1">Scannez le QR code</Typography>
        <Typography variant="body1">
          ou contactez-le au {formatPhone(element.TelephoneAltyorMate)}
        </Typography>
      </div>
      <img
        style={{ width: "150px", height: "150px", padding: "15px" }}
        src={element.QrCode}
        alt={element.Title + " " + element.LastName + " QrCode"}
      />
    </div>
  );
}
function formatPhone(phone: string): string {
  let buffer = "";
  for (let i = 2; i < phone.length + 2; i += 2) {
    buffer += phone.substring(i - 2, i);
    if (i !== phone.length) buffer += ".";
  }
  return buffer;
}

function formatName(name: string): string {
  let nameElement = name.split(" ");
  let buffer = "";
  nameElement.forEach((element: string) => {
    let helper: string = element.toLowerCase();
    buffer += " " + element[0].toUpperCase();
    buffer += helper.substring(1);
  });

  return buffer;
}

function animFold(
  parentDivLeft: RefObject<HTMLDivElement>,
  childDiv: RefObject<HTMLDivElement>
) {
  setTimeout(() => {
    if (parentDivLeft.current != null && childDiv.current != null) {
      if (parentDivLeft.current.offsetWidth < childDiv.current.offsetWidth) {
        parentDivLeft.current.style.width =
          parentDivLeft.current.offsetWidth + 2 + "px";
        animFold(parentDivLeft, childDiv);
      }
    }
  }, 1000 / 490);
}

function animUnFold(
  parentDivLeft: RefObject<HTMLDivElement>,
  childDiv: RefObject<HTMLDivElement>
) {
  setTimeout(() => {
    if (parentDivLeft.current != null) {
      if (parentDivLeft.current.offsetWidth > 0) {
        parentDivLeft.current.style.width =
          parentDivLeft.current.offsetWidth - 2 + "px";
        animUnFold(parentDivLeft, childDiv);
      }
    }
  }, 1000 / 490);
}
