import { createRef, ReactElement, RefObject, useEffect, useRef } from "react";
import "./progressBar.css";
import { Typography } from "@mui/material";

let isFold = false;
export function ProgressBar(props: {
  eventPageManager: Function;
  eventFold: Function;
  page: number;
  numberOfPage: number;
}) {
  let width = 0;
  const child: RefObject<HTMLDivElement> = createRef();
  const parentBar: RefObject<HTMLDivElement> = createRef();
  var id = setInterval(() => {
    width = frame(
      width,
      id,
      child,
      parentBar,
      props.eventPageManager,
      props.eventFold
    );
  }, (1000 * 20) / 490);

  let bulletPage: ReactElement[] = setNumberOfEventBullet(
    props.page + 1,
    "white"
  );
  let bulletVoid: ReactElement[] = setNumberOfEventBullet(
    props.numberOfPage - (props.page + 1),
    "black"
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        bottom: "0px",
        width: "490px",
      }}
    >
      <div id="myProgress" ref={parentBar}>
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        ></div>
        <div id="myBar" ref={child}></div>
      </div>
    </div>
  );
}
function frame(
  width: number,
  id: NodeJS.Timer,
  child: RefObject<HTMLDivElement>,
  parentBar: RefObject<HTMLDivElement>,
  eventPageManager: Function,
  eventFold: Function
) {
  if (width >= parentBar.current!.offsetWidth) {
    clearInterval(id);
    eventPageManager();
    isFold = false;
  } else {
    if (width > 490 - 490 / 20 - 5 && !isFold) {
      eventFold();
      isFold = true;
    }
    width++;
    child.current!.style.width = width + "px";
  }
  return width;
}

function setNumberOfEventBullet(numberOfEvent: number, color: string) {
  let buffer: ReactElement[] = [];
  for (let i = 0; i < numberOfEvent; i++) {
    buffer.push(
      <div style={{ padding: "2px" }} key={i}>
        <svg height="20px" width="20px">
          <circle cx="10" cy="10" r="10" fill={color} />
          Sorry, your browser does not support inline SVG.
        </svg>
      </div>
    );
  }
  return buffer;
}
